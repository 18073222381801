import React, { FunctionComponent } from 'react'
import { View } from 'react-native'

type VerticalSpaceProps = {
  height: number
}

const VerticalSpace: FunctionComponent<VerticalSpaceProps> = (props: VerticalSpaceProps) => {
  return (
    <View style={{ height: props.height }} />
  )
}

export default VerticalSpace
