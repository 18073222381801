import React from 'react';
import { GestureResponderEvent } from 'react-native';
import DefaultButton from './DefaultButton';
import DisabledButton from './DisabledButton';

interface ConditionalButtonProps {
  condition: () => boolean
  onPress: (event: GestureResponderEvent) => void
  text: string
}

function ConditionalButton ({ condition, onPress, text }: ConditionalButtonProps) {
  if (condition()) {
    return <DefaultButton text={text} onPress={onPress}/>
  } else {
    return <DisabledButton text={text}/>
  }
}

export default ConditionalButton
